import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { toggleTheme } from '../../redux/slices/themeSlice';
import { RootState } from '../../redux/store';
import styles from './Menu.module.scss';
import logoImage from '../../assets/minimize-logo.png';

const Menu: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { mode } = useSelector((state: RootState) => state.theme);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  
  // 현재 메인 페이지('/') 또는 about 페이지('/about')인지 확인
  const isSpecialPage = location.pathname === '/' || location.pathname === '/about';

  // 스크롤 이벤트 감지
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsScrolled(scrollTop > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  // 화면 크기 변경 시 모바일 메뉴 상태 처리
  useEffect(() => {
    const handleResize = () => {
      // 화면 너비가 768px 이상이면 모바일 메뉴 닫기
      if (window.innerWidth >= 768 && isMobileMenuOpen) {
        setIsMobileMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobileMenuOpen]);

  // 현재 경로에 따른 활성 메뉴 아이템 확인
  const isActive = (path: string) => {
    return location.pathname === path;
  };

  // 모바일 메뉴 토글
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  // 모바일 메뉴가 열려있을 때 body 스크롤 방지
  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isMobileMenuOpen]);

  const handleThemeToggle = () => {
    dispatch(toggleTheme());
  };

  return (
    <header 
      className={`${styles.header} ${isScrolled ? styles.scrolled : ''} ${isSpecialPage ? styles.mainPage : ''}`}
    >
      <div className={styles.container}>
        {/* 로고 */}
        <div className={styles.logo}>
          <Link to="/">
            <img src={logoImage} alt="Minimize 로고" className={styles.logoImage} />
            {/* <span className={styles.logoText}>MINIMIZE</span> */}
          </Link>
        </div>

        {/* 데스크탑 메뉴 */}
        <nav className={styles.desktopMenu}>
          <ul className={styles.navItems}>
          <li className={isActive('/about') ? styles.active : ''}>
              <Link to="/about">About</Link>
            </li>
            <li className={isActive('/works') ? styles.active : ''}>
              <Link to="/works">Works</Link>
            </li>
            <li className={isActive('/contact') ? styles.active : ''}>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </nav>

        {/* 모바일 메뉴 버튼 */}
        <button 
          className={`${styles.mobileMenuButton} ${isMobileMenuOpen ? styles.open : ''}`}
          onClick={toggleMobileMenu}
          aria-label="메뉴 토글"
        >
          <span></span>
          <span></span>
          <span></span>
        </button>

        {/* 모바일 메뉴 배경 오버레이 */}
        {isMobileMenuOpen && <div className={styles.mobileMenuOverlay} onClick={toggleMobileMenu}></div>}

        {/* 모바일 메뉴 */}
        <div className={`${styles.mobileMenu} ${isMobileMenuOpen ? styles.open : ''}`}>
          <nav>
            <ul className={styles.mobileNavItems}>
              <li className={isActive('/about') ? styles.active : ''}>
                <Link to="/about" onClick={() => setIsMobileMenuOpen(false)}>About</Link>
              </li>
              <li className={isActive('/works') ? styles.active : ''}>
                <Link to="/works" onClick={() => setIsMobileMenuOpen(false)}>Works</Link>
              </li>
              <li className={isActive('/contact') ? styles.active : ''}>
                <Link to="/contact" onClick={() => setIsMobileMenuOpen(false)}>Contact</Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Menu;