import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import Section1 from '../../components/Main/Section1';
import Section2 from '../../components/Main/Section2';
import Section4 from '../../components/Main/Section4';
import Section5 from '../../components/Main/Section5';
import Section6 from '../../components/Main/Section6';
import Contact from '../../components/Contact/Contact';
import Footer from '../../components/Footer/Footer';
import styles from './MainScreen.module.scss';

const MainScreen: React.FC = () => {
  // 테마 상태 가져오기
  const { mode } = useSelector((state: RootState) => state.theme);

  return (
    <div className={`${styles.mainScreen} ${mode === 'light' ? styles.light : styles.dark}`}>
      <Section1 />
      <Section6 />
      <Section5 />
      <Section2 />
      <Section4 />
      <Contact 
        backgroundColor={mode === 'dark' ? '#2a2a2a' : '#f9f9f9'}
        textColor={mode === 'dark' ? '#f0f0f0' : '#333'}
      />
    </div>
  );
};

export default MainScreen;