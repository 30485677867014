import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import styles from './AboutScreen.module.scss';
import Section3 from '../../components/Main/Section3';
import KakaoMap from '../../components/KakaoMap/KakaoMap'; // 경로를 실제 파일 위치에 맞게 조정하세요
import ex1 from '../../assets/ex1.jpg';
import ex2 from '../../assets/ex2.jpg';
import ex3 from '../../assets/ex3.jpg';
import Contact from '../../components/Contact/Contact';
const AboutScreen: React.FC = () => {
  const { mode } = useSelector((state: RootState) => state.theme);
  const [isVisible, setIsVisible] = useState(false);
  const [isMiddleSectionVisible, setIsMiddleSectionVisible] = useState(false);
  const titleRef = useRef<HTMLDivElement>(null);
  const middleSectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    
    // 초기 타이틀 애니메이션
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 300);

    // 중간 섹션 스크롤 애니메이션을 위한 Intersection Observer
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsMiddleSectionVisible(true);
          observer.disconnect();
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
      }
    );

    if (middleSectionRef.current) {
      observer.observe(middleSectionRef.current);
    }

    return () => {
      clearTimeout(timer);
      if (middleSectionRef.current) {
        observer.unobserve(middleSectionRef.current);
      }
    };
  }, []);

  return (
    <div className={`${styles.aboutScreen} ${mode === 'light' ? styles.light : styles.dark}`}>
      {/* 첫 번째 섹션 - "Today We Need minimize" 텍스트 */}
      <section className={styles.heroSection}>
        <div 
          ref={titleRef} 
          className={`${styles.titleContainer} ${isVisible ? styles.visible : ''}`}
        >
          <div className={styles.row}>
            <h2 className={styles.today}>Today</h2>
          </div>
          <div className={styles.row}>
            <h2 className={styles.we}>We</h2>
          </div>
          <div className={styles.row}>
            <h2 className={styles.need}>Need</h2>
          </div>
          <div className={styles.row}>
            <h2 className={styles.minimize}>minimize</h2>
          </div>
        </div>
      </section>
      
      {/* 중간 섹션 - 텍스트와 이미지 배치 (이미지 좌-우-좌 구조) */}
      <div 
        ref={middleSectionRef} 
        className={`${styles.middleSection} ${isMiddleSectionVisible ? styles.visible : ''}`}
      >
        <div className={styles.container}>
          {/* 첫 번째 콘텐츠 블록 - 이미지(왼쪽) + 텍스트(오른쪽) */}
          <div className={styles.contentBlock}>
            <div className={`${styles.imageBlock} ${styles.firstImage}`} style={{ backgroundImage: `url(${ex1})` }}></div>
            
            <div className={`${styles.textBlock} ${styles.textRight}`}>
              <h3 className={styles.title}>단순한 자동화가 아닙니다</h3>
              <p className={styles.description}>당신의 방식에 더 나은 가능성을 더합니다</p>
            </div>
          </div>
          
          {/* 두 번째 콘텐츠 블록 - 텍스트(왼쪽) + 이미지(오른쪽) */}
          <div className={styles.contentBlock}>
            <div className={`${styles.textBlock} ${styles.textLeft}`}>
              <h3 className={styles.title}>당신의 방식에, 우리는 질문합니다</h3>
              <p className={styles.description}>더 나은 방법이 있다면, 우리는 제안합니다</p>
            </div>
            
            <div className={`${styles.imageBlock} ${styles.secondImage}`} style={{ backgroundImage: `url(${ex2})` }}></div>
          </div>
          
          {/* 세 번째 콘텐츠 블록 - 이미지(왼쪽) + 텍스트(오른쪽) */}
          <div className={styles.contentBlock}>
            <div className={`${styles.imageBlock} ${styles.thirdImage}`} style={{ backgroundImage: `url(${ex3})` }}></div>
            
            <div className={`${styles.textBlock} ${styles.textRight}`}>
              <h3 className={styles.title}>더 단순한 길을 함께 만듭니다</h3>
              <p className={styles.description}>복잡한 것을 단순하게, 어려운 것을 쉽게</p>
            </div>
          </div>
        </div>
      </div>
      <Section3 />
      <Contact />
    </div>
  );
};

export default AboutScreen;