import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import styles from './work1.module.scss';

// 예시 이미지 임포트
// 실제 이미지 경로로 변경해야 합니다
import image1 from '../../assets/work1-1.png';
import image2 from '../../assets/work1-2.png';
import image3 from '../../assets/work1-3.png';
import image4 from '../../assets/Work1-4.png';
import image5 from '../../assets/Work1-5.png';
import image6 from '../../assets/Work1-6.png';
import image7 from '../../assets/Work1-7.png';
import heroImage from '../../assets/ex8.jpg';

// 이미지 갤러리 스타일 임포트
import galleryStyles from '../imageGalleryPreview/imageGalleryPreview.module.scss';
import Contact from '../../components/Contact/Contact';
interface WorkImage {
  id: number;
  src: string;
  alt: string;
  text: string;
}

interface CircleFeature {
  id: number;
  src: string;
  alt: string;
  title: string;
  description: string;
}

// 이미지 갤러리 인터페이스
interface ImageItem {
  id: number;
  src: string;
  alt: string;
  title: string;
  description: string;
}

// 카테고리 카드 인터페이스
interface CategoryCard {
  id: number;
  title: string;
  items: string[];
}

const Work1: React.FC = () => {
  const { mode } = useSelector((state: RootState) => state.theme);
  const [imagesVisible, setImagesVisible] = useState<boolean[]>([false, false, false]);
  const [titleVisible, setTitleVisible] = useState<boolean>(false);
  const [descriptionVisible, setDescriptionVisible] = useState<boolean>(false);
  const [featuresVisible, setFeaturesVisible] = useState<boolean>(false);
  const [galleryVisible, setGalleryVisible] = useState<boolean>(false);
  const [cardsVisible, setCardsVisible] = useState<boolean>(false);
  const imagesRef = useRef<(HTMLDivElement | null)[]>([]);
  const titleRef = useRef<HTMLDivElement>(null);
  const descriptionRef = useRef<HTMLDivElement>(null);
  const featuresRef = useRef<HTMLDivElement>(null);
  const galleryRef = useRef<HTMLDivElement>(null);
  const cardsRef = useRef<HTMLDivElement>(null);
  
  // 이미지 갤러리 관련 상태
  const [selectedImage, setSelectedImage] = useState<ImageItem | null>(null);

  // 샘플 이미지 데이터
  const workImages: WorkImage[] = [
    // {
    //   id: 1,
    //   src: image1,
    //   alt: "AS IS",
    //   text: "업무의 모든 과정을 최소화 시킬 수 있도록 ERP솔루션에 모든 과정을 관리할 수 있게 설계하였습니다"
    // },
    {
      id: 1,
      src: image3, 
      alt: "To Be",
      text: "업무의 모든 과정을 최소화 시킬 수 있도록 ERP솔루션에 모든 과정을 관리할 수 있게 설계하였습니다"
    },
  ];

  // 카테고리 카드 데이터
  const categoryCards: CategoryCard[] = [
    {
      id: 1,
      title: "사입",
      items: [
        "해외몰에서 상품수집(1688, 타오바오 등)",
        "상품명 및 옵션, 이미지 편집, 상세정보 등록",
        "배대지 현지 관리",
        "구매대행 업체관리"
      ]
    },
    {
      id: 2,
      title: "물류",
      items: [
        "상품별 통관관리(인종여부 등)",
        "배송추적"
      ]
    },
    {
      id: 3,
      title: "재고",
      items: [
        "창고/기물관리",
        "재고 관리(위치, 수량, 차기할때 최적루트 알고리즘)",
        "부족 수량 자동 계산",
        "바코드 시스템",
        "모바일(태블릿) 연동으로 창고에서 돌아다니며 일함",
        "로봇 연동"
      ]
    },
    {
      id: 4,
      title: "정산",
      items: [
        "매출/마진/단가 계산",
        "기간별/발주별 매출",
        "물류비 관리"
      ]
    },
    {
      id: 5,
      title: "자사몰",
      items: [
        "카페 24 상품 자동 등록",
        "스마트스토어 상품 자동등록",
        "재고/가격 연동",
        "리뷰/평점 관리"
      ]
    }
  ];

  // imagesRef 초기화 (이미지 배열 길이에 맞게)
  useEffect(() => {
    imagesRef.current = imagesRef.current.slice(0, workImages.length);
    setImagesVisible(Array(workImages.length).fill(false));
  }, [workImages.length]);

  // 원형 이미지와 설명을 위한 데이터
  const circleFeatures: CircleFeature[] = [
    {
      id: 1,
      src: image1,
      alt: "주요 특징 1",
      title: "Hub / WMS 연동",
      description: "발주서, 입고, 하차, 쉽먼트, 밀크런까지 쿠팡 판매 업무를 자동으로 연결합니다"
    },
    {
      id: 2,
      src: image2,
      alt: "주요 특징 2",
      title: "소싱 · 재고관리 자동화",
      description: "해외 사이트 상품정보 크롤링부터 재고·판매 현황 통합 관리까지"
    },
    {
      id: 3,
      src: image1,
      alt: "주요 특징 3",
      title: "모바일 APP 지원",
      description: "PDA 없이, 스마트폰으로 입출고·재고 관리 카메라로 바코드를 스캔해 바로 처리"
    }
  ];

  // 이미지 갤러리 데이터 - 최종 버전
  const galleryImages: ImageItem[] = [
    {
      id: 1,
      src: image4,
      alt: "발주서 연동",
      title: "상품 소싱 및 등록",
      description: "해외 쇼핑몰(예: 타오바오, 1688) 등에서\n상품 정보를 자동 수집해\n본사 데이터 베이스에 저장\n필요한 정보들을 추가 입려하여 등록/견적\n🡒 자동 크롤링 → DB 저장 → 등록 양식 생성"
    },
    {
      id: 2,
      src: image5,
      alt: "재고 추적",
      title: "발주서 처리 및 입출고 프로세스",
      description: "쿠팡측 발주서 자동연동\n본사 DB에서 자동 입출고 처리\n하차, 쉽먼트, 밀크런 자동화 등\n🡒 발주서동록 → 박스(토트)입고 → 출고"
    },
    {
      id: 3,
      src: image6, 
      alt: "바코드 입력",
      title: "재고관리, 자산관리",
      description: "창고 현황, 상품현황\nPDA(앱) 실시간 상품찾기\n매출 집계, 재고자산 관리, 판매가 조정 등"
    },
    {
      id: 4,
      src: image7, // 네 번째 이미지 - 실제 이미지로 교체 필요
      alt: "제작및 사입 관리",
      title: "제작및 사입 관리",
      description: "부족재고량 실시간 계산\n제작및 사입 프로세스 자동화\n통관 및 물류 추적\n물류비 정산, 재고 자동 반영"
    }
  ];

  // 컴포넌트 마운트 시 첫 번째 이미지 선택
  useEffect(() => {
    if (galleryImages.length > 0) {
      setSelectedImage(galleryImages[0]);
    }
  }, []);

  // IntersectionObserver를 사용하여 요소가 화면에 보이는지 감지
  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1 // 10% 이상 보이면 감지
    };

    // 타이틀 관찰자
    const titleObserver = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setTitleVisible(true);
          titleObserver.unobserve(entry.target);
        }
      });
    }, observerOptions);

    // 원형 이미지 섹션 관찰자
    const featuresObserver = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setFeaturesVisible(true);
          featuresObserver.unobserve(entry.target);
        }
      });
    }, observerOptions);

    // 이미지 관찰자
    const imageObservers = imagesRef.current.map((ref, index) => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setImagesVisible(prev => {
              const newState = [...prev];
              newState[index] = true;
              return newState;
            });
            observer.unobserve(entry.target);
          }
        });
      }, { ...observerOptions, threshold: 0.2 });
      return observer;
    });

    // 갤러리 관찰자
    const galleryObserver = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setGalleryVisible(true);
          galleryObserver.unobserve(entry.target);
        }
      });
    }, observerOptions);

    // 설명 관찰자
    const descriptionObserver = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setDescriptionVisible(true);
          descriptionObserver.unobserve(entry.target);
        }
      });
    }, observerOptions);

    // 카드 섹션 관찰자
    const cardsObserver = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setCardsVisible(true);
          cardsObserver.unobserve(entry.target);
        }
      });
    }, observerOptions);

    // 각 요소에 관찰자 등록
    if (titleRef.current) {
      titleObserver.observe(titleRef.current);
    }

    if (featuresRef.current) {
      featuresObserver.observe(featuresRef.current);
    }

    imagesRef.current.forEach((ref, index) => {
      if (ref) imageObservers[index].observe(ref);
    });

    if (galleryRef.current) {
      galleryObserver.observe(galleryRef.current);
    }

    if (descriptionRef.current) {
      descriptionObserver.observe(descriptionRef.current);
    }

    if (cardsRef.current) {
      cardsObserver.observe(cardsRef.current);
    }

    // 컴포넌트 언마운트 시 관찰자 해제
    return () => {
      if (titleRef.current) titleObserver.unobserve(titleRef.current);
      
      if (featuresRef.current) featuresObserver.unobserve(featuresRef.current);
      
      imagesRef.current.forEach((ref, index) => {
        if (ref) imageObservers[index].unobserve(ref);
      });
      
      if (galleryRef.current) galleryObserver.unobserve(galleryRef.current);
      
      if (descriptionRef.current) descriptionObserver.unobserve(descriptionRef.current);
      
      if (cardsRef.current) cardsObserver.unobserve(cardsRef.current);
    };
  }, []);

  // 이미지 선택 핸들러
  const handleImageSelect = (image: ImageItem) => {
    setSelectedImage(image);
  };

  return (
    <div className={`${styles.work1Container} ${mode === 'dark' ? styles.dark : ''}`}>
        <div 
          ref={titleRef} 
          className={`${styles.titleSection} ${titleVisible ? styles.visible : ''}`}
        >
          <h1 className={styles.title}>쿠팡 판매관리 자동화 솔루션 (Hub/WMS)</h1>
          <div className={styles.overlay}></div>
          <img 
          src={heroImage} 
          alt="쿠팡 ERP 자동화 솔루션" 
          className={styles.backgroundImage} 
        />
        </div>
        <p className={styles.subtitle}>
        <span className={styles.impact}>복잡한 Hub와 WMS 작업</span>, 한 번에 자동화하세요<br />
        발주서 관리부터 재고, 배송, 입고, 견적까지<br />
          <span className={styles.impact}>minimize</span>의 노하우로 쉽고 빠르게 해결하세요
        </p>
      {/* 새로운 원형 이미지 섹션 */}
      <div
        ref={featuresRef}
        className={`${styles.featuresSection} ${featuresVisible ? styles.visible : ''}`}
      >
        <div className={styles.featuresContainer}>
          {circleFeatures.map((feature, index) => (
            <div 
              key={feature.id} 
              className={styles.featureItem}
              style={{ animationDelay: `${index * 0.2}s` }}
            >
              <div className={styles.circleImageContainer}>
                <img src={feature.src} alt={feature.alt} className={styles.circleImage} />
              </div>
              <div className={styles.featureContent}>
                <h3 className={styles.featureTitle}>{feature.title}</h3>
                <p className={styles.featureDescription}>{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      
      {/* 이미지 갤러리 섹션 */}
      <div className={styles.imageGallery}>
        {workImages.map((image, index) => (
          <div 
            key={image.id}
            ref={(el) => { imagesRef.current[index] = el }}
            className={`${styles.imageContainer} ${imagesVisible[index] ? styles.visible : ''}`}
            style={{ animationDelay: `${index * 0.2}s` }}
          >
            <div className={styles.imageWrapper}>
              <img src={image.src} alt={image.alt} className={styles.workImage} />
            </div>
            <p className={styles.imageText}>{image.text}</p>
          </div>
        ))}
      </div>
      
      {/* 최종 수정된 이미지 갤러리 - 번호 추가 */}
      <div 
        ref={galleryRef}
        className={`${styles.galleryPreviewSection} ${galleryVisible ? styles.visible : ''}`}
      >
        <div className={galleryStyles.galleryContainer}>
          <div className={galleryStyles.galleryWrapper}>
            {/* 왼쪽 섹션 - 큰 이미지 */}
            <div className={galleryStyles.leftSection}>
              <div className={galleryStyles.mainImageWrapper}>
                {selectedImage && (
                  <img 
                    src={selectedImage.src} 
                    alt={selectedImage.alt}
                    className={galleryStyles.mainImage}
                  />
                )}
              </div>
            </div>
            
            {/* 오른쪽 섹션 - 선택된 이미지의 번호, 제목과 설명 */}
            <div className={galleryStyles.rightSection}>
              {selectedImage && (
                <>
                  <span className={galleryStyles.imageNumber}>0{selectedImage.id}</span>
                  <h2 className={galleryStyles.imageTitle}>{selectedImage.title}</h2>
                  <p className={galleryStyles.imageDescription}>
                    {selectedImage.description.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        {index < selectedImage.description.split('\n').length - 1 && <br />}
                      </React.Fragment>
                    ))}
                  </p>
                </>
              )}
              
              {/* 가로로 정렬된 썸네일 컨테이너 */}
              <div className={galleryStyles.thumbnailContainer}>
                {galleryImages.map((image) => (
                  <div 
                    key={image.id}
                    className={`${galleryStyles.thumbnailItem} ${selectedImage && selectedImage.id === image.id ? galleryStyles.active : ''}`}
                    onClick={() => handleImageSelect(image)}
                  >
                    <div className={galleryStyles.thumbnailImageWrapper}>
                      <img 
                        src={image.src} 
                        alt={image.alt}
                        className={galleryStyles.thumbnailImage}
                      />
                    </div>
                    <div className={galleryStyles.thumbnailContent}>
                      <span className={galleryStyles.thumbnailNumber}>0{image.id}</span>
                      <h3 className={galleryStyles.thumbnailTitle}>{image.title}</h3>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* 카테고리 카드 섹션 */}
      <div 
        ref={cardsRef}
        className={`${styles.categoryCardsSection} ${cardsVisible ? styles.visible : ''}`}
      >
        <div className={styles.categoryCardsContainer}>
          {categoryCards.map((card, index) => (
            <div 
              key={card.id} 
              className={styles.categoryCard}
              style={{ animationDelay: `${index * 0.1}s` }}
            >
              <h3 className={styles.cardTitle}>{card.title}</h3>
              <ul className={styles.cardItemsList}>
                {card.items.map((item, itemIndex) => (
                  <li 
                    key={itemIndex} 
                    className={styles.cardItem}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
      
      <div 
        ref={descriptionRef}
        className={`${styles.descriptionSection} ${descriptionVisible ? styles.visible : ''}`}
      >
        <div className={styles.descriptionWrapper}>
          <h2 className={styles.descriptionTitle}>프로젝트 상세 설명</h2>
          <p className={styles.descriptionText}>
            로켓배송의 복잡하고 어렵던 업무를 ERP를 통하여 쉽고 간편하게 만들 수 있는것에 집중하였습니다.
            기존의 복잡하던 엑셀 방식의 업무에서 벗어나 직관적인 UI를 통하여 실수와 중복으로 소비되는 시간을 줄이고
            바코드 관리방식을 통하여 업무의 난이도를 낮췄습니다.
          </p>
          <p className={styles.descriptionText}>
            미니마이즈를 통해 발주서 관리부터 재고, 배송, 입고, 견적까지 로켓배송의 모든 과정을 쉽고 간편하게 관리해보세요.
            사용자에게 맞춤 솔루션과 익숙한 UI를 제공해드립니다.            
          </p>
        </div>
      </div>
      <Contact 
        backgroundColor={mode === 'dark' ? '#2a2a2a' : '#f9f9f9'}
        textColor={mode === 'dark' ? '#f0f0f0' : '#333'}
      />
    </div>
  );
};

export default Work1;