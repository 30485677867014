import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from './redux/store';
import LoadingScreen from './screens/LoadingScreen/LoadingScreen';
import Menu from './components/Menu/Menu';
import MainScreen from './screens/MainScreen/MainScreen';
import AboutScreen from './screens/AboutScreen/AboutScreen';
import WorksScreen2 from './screens/WorksScreen2/WorksScreen2';
import WorksScreen from './screens/WorksScreen/WorksScreen';
import ContactScreen from './screens/ContactScreen/ContactScreen';
import ScrollToTop from './components/ScrollToTop';
import Footer from './components/Footer/Footer';
import './styles/global.scss';

// HTTPS 리다이렉션을 위한 래퍼 컴포넌트
const HttpsRedirect: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    if (
      window.location.protocol === 'http:' && 
      window.location.hostname !== 'localhost' && 
      window.location.hostname !== '127.0.0.1'
    ) {
      window.location.href = `https://${window.location.host}${location.pathname}${location.search}${location.hash}`;
    }
  }, [location]);

  return <>{children}</>;
};

const App: React.FC = () => {
  const { mode } = useSelector((state: RootState) => state.theme);
  const { isLoading } = useSelector((state: RootState) => state.ui);
  const [hasLoaded, setHasLoaded] = useState(false);
  
  // 다크/라이트 모드에 따라 body 클래스 변경
  useEffect(() => {
    document.body.className = mode;
    
    // HTML 요소에도 data-theme 속성 추가 (추가적인 테마 스타일링에 유용)
    document.documentElement.setAttribute('data-theme', mode);
  }, [mode]);
  
  // 컴포넌트가 처음 마운트될 때 실행
  useEffect(() => {
    // 이미 로딩된 적이 있으면 hasLoaded를 true로 설정
    const hasVisitedBefore = sessionStorage.getItem('hasLoaded');
    if (hasVisitedBefore) {
      setHasLoaded(true);
    }
  }, []);
  
  // 로딩이 완료되면 세션 스토리지에 기록
  useEffect(() => {
    if (!isLoading && !hasLoaded) {
      sessionStorage.setItem('hasLoaded', 'true');
      setHasLoaded(true);
    }
  }, [isLoading, hasLoaded]);

  // 새로고침 시에는 hasLoaded가 true이므로 로딩 화면을 건너뜁니다
  const shouldShowLoading = isLoading && !hasLoaded;

  return (
    <Router>
      <HttpsRedirect>
        <ScrollToTop /> {/* 페이지 전환 시 스크롤을 맨 위로 이동 */}
        <div className="app">
          <Menu />
          {shouldShowLoading ? (
            <LoadingScreen duration={3000} />
          ) : (
            <main className="main-content">
              <Routes>
                <Route path="/" element={<MainScreen />} />
                <Route path="/about" element={<AboutScreen />} />
                <Route path="/works" element={<WorksScreen />} />
                <Route path="/contact" element={<ContactScreen />} />
                <Route path="/works/:workId" element={<WorksScreen2 />} />
                <Route path="*" element={<div className="container">페이지가 준비 중입니다.</div>} />
              </Routes>
            </main>
          )}
          <Footer/>
        </div>
      </HttpsRedirect>
    </Router>
  );
};

export default App;