import React, { useEffect, useRef, useState } from 'react';
import { whatWeDoData, SlideContent } from '../../data/whatWeDoData';
import styles from './Section3.module.scss';

const Section3: React.FC = () => {
  const sectionRef = useRef<HTMLElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const rightPanelRef = useRef<HTMLDivElement>(null);
  const leftPanelRef = useRef<HTMLDivElement>(null);
  const coverRef = useRef<HTMLDivElement>(null);
  const [activeSlide, setActiveSlide] = useState<number>(0);
  const [prevActiveSlide, setPrevActiveSlide] = useState<number>(0);
  const [isChanging, setIsChanging] = useState<boolean>(false);
  const [scrollDirection, setScrollDirection] = useState<'up' | 'down'>('down');
  const [coverVisible, setCoverVisible] = useState<boolean>(true);
  const scrollTimeout = useRef<NodeJS.Timeout | null>(null);
  const lastScrollPositionRef = useRef<number>(0);

  // data 파일에서 가져온 슬라이드 데이터 사용
  const slides: SlideContent[] = whatWeDoData;

  // 텍스트에서 줄바꿈 처리 함수
  const formatText = (text: string) => {
    if (!text) return null;
    return text.split('\\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index < text.split('\\n').length - 1 && <br />}
      </React.Fragment>
    ));
  };

  // 스크롤을 감지하여 어떤 슬라이드를 보여줄지 결정하는 함수
  useEffect(() => {
    // 섹션의 높이를 설정 - 슬라이드 수에 따라 조정
    const updateSectionHeight = () => {
      if (sectionRef.current) {
        // 모바일에서는 각 슬라이드마다 더 많은 공간 필요
        const isMobile = window.innerWidth <= 1024;
        // 슬라이드 수에 비례하여 높이 설정 (표지 포함)
        const totalHeight = window.innerHeight * (slides.length + 1) * (isMobile ? 1.2 : 1);
        sectionRef.current.style.height = `${totalHeight}px`;
      }
    };
    
    updateSectionHeight();
    window.addEventListener('resize', updateSectionHeight);

    const handleScroll = () => {
      if (!sectionRef.current || !coverRef.current) return;
      
      // 현재 스크롤 위치
      const currentScrollPosition = window.pageYOffset;
      
      // 스크롤 방향 감지
      if (currentScrollPosition > lastScrollPositionRef.current) {
        setScrollDirection('down');
      } else if (currentScrollPosition < lastScrollPositionRef.current) {
        setScrollDirection('up');
      }
      
      // 현재 스크롤 위치 저장
      lastScrollPositionRef.current = currentScrollPosition;
      
      // 섹션에 상대적인 스크롤 위치 계산
      const sectionTop = sectionRef.current.offsetTop;
      const sectionEnd = sectionTop + sectionRef.current.offsetHeight - window.innerHeight;
      const coverHeight = window.innerHeight;
      
      // 표지 이동 처리
      if (currentScrollPosition > sectionTop) {
        // 스크롤이 섹션 시작점을 넘어가면 표지가 위로 이동하도록 설정
        const scrollProgress = Math.min((currentScrollPosition - sectionTop) / coverHeight, 1);
        coverRef.current.style.transform = `translateY(-${scrollProgress * 100}%)`;
        
        // 스크롤이 충분히 내려갔을 때 표지 완전히 숨기기
        if (scrollProgress >= 0.8) {
          setCoverVisible(false);
        } else {
          setCoverVisible(true);
        }
      } else {
        // 섹션 시작점 이전에는 표지를 원래 위치로
        coverRef.current.style.transform = 'translateY(0)';
        setCoverVisible(true);
      }
      
      // 컨테이너 상태 설정 - 고정 또는 일반 흐름
      if (containerRef.current) {
        if (window.pageYOffset >= sectionTop && window.pageYOffset <= sectionEnd) {
          // 섹션 내부에 있을 때 - 컨테이너를 화면에 고정
          containerRef.current.classList.add(styles.fixed);
          containerRef.current.classList.remove(styles.bottom);
        } else if (window.pageYOffset < sectionTop) {
          // 섹션 시작점 이전 - 기본 상태
          containerRef.current.classList.remove(styles.fixed);
          containerRef.current.classList.remove(styles.bottom);
        } else {
          // 섹션 끝점 이후 - 하단에 고정
          containerRef.current.classList.remove(styles.fixed);
          containerRef.current.classList.add(styles.bottom);
        }
      }
      
      // 활성 슬라이드 계산 (표지가 인덱스 0, 슬라이드가 1부터 시작)
      if (window.pageYOffset >= sectionTop && window.pageYOffset <= sectionEnd) {
        // 스크롤 진행도에 따라 활성 슬라이드 계산
        const scrollProgress = (window.pageYOffset - sectionTop) / 
                              (sectionEnd - sectionTop);
        
        // 활성 슬라이드 인덱스 계산 (0은 표지, 1~n은 실제 슬라이드)
        const slideIndex = Math.min(
          Math.floor(scrollProgress * (slides.length + 1)),
          slides.length
        );
        
        // 활성 슬라이드 인덱스가 변경된 경우에만 상태 업데이트
        if (slideIndex !== activeSlide) {
          // 변경 중 상태로 설정
          setIsChanging(true);
          
          // 기존 타임아웃 제거
          if (scrollTimeout.current) {
            clearTimeout(scrollTimeout.current);
          }
          
          // 이전 슬라이드 기록
          setPrevActiveSlide(activeSlide);
          
          // 새 활성 슬라이드 설정
          setActiveSlide(slideIndex);
          
          // 일정 시간 후 변경 상태 초기화
          scrollTimeout.current = setTimeout(() => {
            setIsChanging(false);
          }, 800); // 트랜지션 시간과 일치시킴
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll); // 화면 크기 변경 시에도 상태 업데이트
    handleScroll(); // 초기 확인
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
      window.removeEventListener('resize', updateSectionHeight);
      if (scrollTimeout.current) {
        clearTimeout(scrollTimeout.current);
      }
    };
  }, [activeSlide, slides.length]);

  return (
    <section ref={sectionRef} className={styles.section3}>
      {/* 주요 슬라이드 컨테이너 */}
      <div ref={containerRef} className={styles.container}>
        {/* 표지 슬라이드와 스텝 슬라이드를 모두 포함하는 슬라이드 컨테이너 */}
        <div className={styles.slidesContainer}>
          {/* 표지 슬라이드 - 항상 보이도록 수정 */}
          <div 
            ref={coverRef}
            className={`
              ${styles.coverSlide} 
              ${coverVisible ? styles.visible : ''}
            `}
          >
            <div className={styles.coverBackground}></div>
            <div className={styles.coverOverlay}></div>
            <div className={styles.coverContent}>
              <h2 className={styles.coverTitle}>미니마이즈의 원칙</h2>
              <div className={styles.coverColumns}>
                <div className={styles.coverLeft}>
                  <span className={styles.coverPhase}>분석 중심</span>
                </div>
                <div className={styles.coverDivider}></div>
                <div className={styles.coverRight}>
                  <span className={styles.coverPhase}>고객 시점/성과 중심</span>
                </div>
              </div>
              <div className={styles.scrollDownIndicator}>
                <span>스크롤을 내려 6단계 과정을 확인하세요</span>
                <div className={styles.scrollArrow}></div>
              </div>
            </div>
          </div>

          {/* 왼쪽 패널 - subtitle만 표시 */}
          <div ref={leftPanelRef} className={styles.leftPanel}>
            {/* 왼쪽 패널 배경 이미지 */}
            {slides.map((slide, index) => (
              <div 
                key={`left-bg-${slide.id}`}
                className={`
                  ${styles.leftPanelBackground} 
                  ${activeSlide === index + 1 ? styles.active : ''}
                  ${prevActiveSlide === index + 1 ? styles.previous : ''}
                  ${scrollDirection === 'down' ? styles.scrollDown : styles.scrollUp}
                `}
                style={{
                  backgroundImage: slide.leftPanel?.backgroundImage 
                    ? `url(${slide.leftPanel.backgroundImage})` 
                    : 'none'
                }}
              ></div>
            ))}
            
            <div className={styles.leftPanelOverlay}></div>
            
            <div className={styles.fixedContent}>
              {/* 왼쪽 패널 텍스트 슬라이드 - 배경과 같은 방향으로 이동 */}
              {slides.map((slide, index) => (
                slide.leftPanel.subtitle && (
                  <div 
                    key={`subtitle-${slide.id}`}
                    className={`
                      ${styles.textSlide} 
                      ${activeSlide === index + 1 ? styles.active : ''}
                      ${prevActiveSlide === index + 1 ? styles.previous : ''}
                      ${scrollDirection === 'down' ? styles.scrollDown : styles.scrollUp}
                    `}
                  >
                    {/* 스텝 번호 추가 */}
                    <div className={styles.stepNumber}>
                      <span className={styles.stepLabel}>STEP</span>
                      {index + 1}
                    </div>
                    
                    <div className={styles.contentText}>
                      {formatText(slide.leftPanel.subtitle)}
                    </div>
                  </div>
                )
              ))}
            </div>
          </div>
          
          {/* 오른쪽 패널 - description만 표시 */}
          <div ref={rightPanelRef} className={styles.rightPanel}>
            {slides.map((slide, index) => (
              <div 
                key={slide.id}
                className={`
                  ${styles.slide} 
                  ${activeSlide === index + 1 ? styles.active : ''}
                  ${prevActiveSlide === index + 1 ? styles.previous : ''}
                  ${scrollDirection === 'down' ? styles.scrollDown : styles.scrollUp}
                `}
                style={{
                  backgroundImage: slide.backgroundImage 
                    ? `url(${slide.backgroundImage})` 
                    : 'none',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundColor: !slide.backgroundImage ? '#000' : 'transparent'
                }}
              >
                <div className={styles.slideContent}>
                  {/* 오직 description만 표시 */}
                  {slide.description && (
                    <div className={styles.contentText}>
                      {formatText(slide.description)}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section3;