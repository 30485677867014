import React, { useEffect, useRef, useState } from 'react';
import styles from './Section5.module.scss';

interface TextItem {
  id: number;
  text: React.ReactNode;
  color: 'white' | 'orange' | 'mixed';
  size?: 'xxl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs' | 'xxs' | 'final';
}

const Section5: React.FC = () => {
  const sectionRef = useRef<HTMLElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  // 현재 보이는 텍스트의 인덱스
  const [activeTextIndex, setActiveTextIndex] = useState<number>(0);
  // 컨테이너의 상태 관리 (상단, 고정, 하단)
  const [containerState, setContainerState] = useState<'top' | 'fixed' | 'bottom'>('top');

  // 텍스트 항목 데이터 - 각 항목마다 다른 크기 지정
  const textItems: TextItem[] = [
    // 이전 id 1-4 항목들이 제거됨
    { id: 5, text: "복잡한 업무를", color: 'white', size: 'xl' },    
    { id: 6, text: "단순 반복을", color: 'white', size: 'xl' },      
    { id: 7, text: "지루한 보고를", color: 'white', size: 'lg' },     
    { id: 8, text: "시간 낭비를", color: 'white', size: 'md' },       
    { id: 9, text: "스트레스를", color: 'white', size: 'sm' },        
    { id: 10, text: "실수를", color: 'white', size: 'xxs' },          
    // 마지막 항목 - 특별한 더 큰 크기로 설정
    { id: 11, text: <div className={styles.workMinimize}><span className={styles.whiteText}>Work</span></div>, color: 'mixed', size: 'final' }
  ];

  useEffect(() => {
    const handleScroll = () => {
      if (!sectionRef.current) return;

      // 페이지 스크롤 위치 가져오기
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;

      // 섹션의 시작과 끝 위치 계산
      const sectionTop = sectionRef.current.offsetTop;
      const sectionHeight = sectionRef.current.offsetHeight;

      // fixed 영역 시작/끝 지점 설정
      const fixedStartPoint = sectionTop - windowHeight * 0.2; // 섹션 시작 전 20% 지점부터 fixed
      const fixedEndPoint = sectionTop + sectionHeight * 0.6; // 섹션 시작 후 60% 지점까지 fixed

      // 컨테이너 상태 업데이트
      if (scrollTop < fixedStartPoint) {
        // 아직 fixed 영역에 도달하지 않음 (상단 영역)
        setContainerState('top');
      } else if (scrollTop >= fixedStartPoint && scrollTop < fixedEndPoint) {
        // fixed 영역 내부
        setContainerState('fixed');

        // 스크롤 진행률에 따라 활성 텍스트 인덱스 계산
        const progressInFixedArea = (scrollTop - fixedStartPoint) / (fixedEndPoint - fixedStartPoint);
        const textIndex = Math.min(
          Math.floor(progressInFixedArea * textItems.length),
          textItems.length - 1
        );
        setActiveTextIndex(textIndex);
      } else {
        // fixed 영역을 지나침 (하단 영역)
        setContainerState('bottom');
        setActiveTextIndex(textItems.length - 1); // 마지막 텍스트 보이게 유지
      }
    };

    // 스크롤 이벤트 리스너 등록 (throttle 적용하여 성능 최적화)
    let ticking = false;
    const scrollListener = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          handleScroll();
          ticking = false;
        });
        ticking = true;
      }
    };

    window.addEventListener('scroll', scrollListener);
    // 초기 실행
    handleScroll();

    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  }, [textItems.length]);

  return (
    <section ref={sectionRef} className={styles.section5}>
      <div 
        ref={containerRef} 
        className={`${styles.container} ${containerState === 'fixed' ? styles.fixed : ''} ${containerState === 'bottom' ? styles.bottom : ''}`}
      >
        <div className={`${styles.contentWrapper} ${styles.centerContent}`}>
          <div className={styles.fullWidthContent}>
            {activeTextIndex <= 5 ? (
              // 중간 단계 (복잡한 업무를 ~ 실수를)
              <div 
                className={`${styles.centeredPair} ${styles[`size${textItems[activeTextIndex].size?.toUpperCase()}`] || ''} ${styles.active}`}
              >
                <span className={styles.whiteText}>{textItems[activeTextIndex].text}</span>
                <span className={styles.orangeText}>minimize</span>
              </div>
            ) : activeTextIndex === 6 ? (
              // 마지막 'Work minimize' 단계 - 특별한 크기 적용
              <div className={`${styles.centeredPair} ${styles.sizeFINAL} ${styles.active}`}>
                {textItems[6].text}
                <span className={styles.orangeText}>minimize</span>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      {/* 고정 컨테이너를 위한 공간 확보 (fixed 상태일 때 일반 흐름에서 빠지므로) */}
      <div className={styles.spacer} style={{ 
        height: containerState === 'fixed' ? '100vh' : '0' 
      }} />
    </section>
  );
};

export default Section5;