import React, { useRef } from 'react';
import styles from './Section6.module.scss';

interface DefinitionItem {
  type: string;
  definitions: string[];
  examples?: string[];
}

const Section6: React.FC = () => {
  const sectionRef = useRef<HTMLElement>(null);
  const definitionRef = useRef<HTMLDivElement>(null);
  
  // 사전 정의 데이터
  const dictionaryData: DefinitionItem[] = [
    {
      type: "동사",
      definitions: [
        "어떤 것을 가능한 한 최소 크기나 정도로 줄이다.",
        "컴퓨터 화면에서 창을 아이콘으로 최소화하다."
      ],
    },
    {
      type: "형용사",
      definitions: [
        "최소한의 상태에 관한; 가능한 한 가장 적은.",
        "디자인, 예술 또는 생활 방식에서 미니멀리즘과 관련된."
      ]
    }
  ];



  return (
    <section ref={sectionRef} className={styles.section6}>
      <div className={styles.container}>
        <div ref={definitionRef} className={styles.dictionaryCard}>
          <div className={styles.dictionaryHeader}>
            <h1 className={styles.word}>
              Minimize
            </h1>
            <div className={styles.phonetic}>
              <span className={styles.phoneticText}>
              /ˈmɪn.ə.maɪz/
              </span>
            </div>
          </div>

          <div className={styles.definitionsContainer}>
            {dictionaryData.map((item, itemIndex) => (
              <div key={`type-${itemIndex}`} className={styles.definitionBlock}>
                <div className={styles.wordType}>
                  {item.type}
                </div>
                
                <ol className={styles.definitionsList}>
                  {item.definitions.map((definition, defIndex) => (
                    <li 
                      key={`def-${itemIndex}-${defIndex}`}
                      className={styles.definitionItem}
                    >
                      {definition}
                    </li>
                  ))}
                </ol>
                
                {item.examples && (
                  <div className={styles.examplesContainer}>
                    {item.examples.map((example, exIndex) => (
                      <p 
                        key={`ex-${itemIndex}-${exIndex}`}
                        className={styles.exampleItem}
                      >
                        "{example}"
                      </p>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section6;